<template>
    <div class=container>
        <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"> <font size="+2">About the Samples</font></div></td>
          </tr>
          <tr>
            <td valign="baseline"><div align="left">A collection of 61 real-world is surfaces used in the measurements.
The samples were chosen to span a wide
range of geometric and photometric properties.
The categories include
specular surfaces (aluminum foil, artificial grass),
diffuse surfaces (plaster, concrete),
isotropic surfaces (cork, leather, styrofoam),
anisotropic surfaces (straw, corduroy, corn husk),
surfaces with large height variations (crumpled paper, terrycloth, pebbles),
surfaces with small height variations (sandpaper, quarry tile, brick),
pastel surfaces (paper, cotton),
colored surfaces (velvet, rug),
natural surfaces (moss, lettuce, fur)
and man-made surfaces (sponge, terrycloth, velvet).
Different samples of the same type of surfaces are
denoted by letters, e.g. Brick_a and Brick_b.
Samples 29, 30, 31 and 32 are close-up views of samples 2, 11, 12 and
14, respectively.
                      <hr>
                  </div>              </td>
          </tr>
          <tr>
            <td valign="baseline"><em>Click on a sample for full image.</em>
              <br>
              <br>              
              <center>
                <table>
  <tbody><tr>
    <td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample01.gif" class="style4"><strong>1-Felt</strong></a>    
    </td><td valign="baseline" class="style4"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample02.gif"><strong>2-Polyester </strong></a>    
    </td><td valign="baseline" class="style4"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample03.gif"><strong>3-Terrycloth </strong></a>    
    </td><td valign="baseline" class="style4"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample04.gif"><strong>4-Rough Plastic</strong></a>    
    </td><td valign="baseline" class="style4"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample05.gif"><strong>5-Leather </strong></a>    
    </td><td valign="baseline" class="style4"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample06.gif"><strong>6-Sandpaper </strong></a>    
    </td><td valign="baseline" class="style4"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample07.gif"><strong>7-Velvet </strong></a>    </td></tr><tr>
      <td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample01.gif"><img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut01.gif"></a>      
      </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample02.gif"><img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut02.gif"></a>    
      </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample03.gif"><img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut03.gif"></a>    
      </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample04.gif"><img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut04.gif"></a>    
      </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample05.gif"><img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut05.gif"></a>    
      </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample06.gif"><img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut06.gif"></a>    
      </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample07.gif"><img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut07.gif"></a>    
    </td></tr><tr class="style4">
      <td valign="baseline">    
      </td><td valign="baseline">    
      </td><td valign="baseline">    
      </td><td valign="baseline">    
      </td><td valign="baseline">    
      </td><td valign="baseline">    
      </td><td valign="baseline">    
    </td></tr><tr class="style4">
      <td valign="baseline">
      </td><td valign="baseline">    
      </td><td valign="baseline">    
      </td><td valign="baseline">    
      </td><td valign="baseline">    
      </td><td valign="baseline">    
      </td><td valign="baseline">    
    </td></tr><tr class="style4">
      <td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample08.gif" class="style5"><strong>8-Pebbles</strong></a>        
      </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample09.gif" class="style5"><strong>9-Frosted Glass</strong></a>        
      </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample10.gif" class="style5"><strong>10-Plaster_a</strong></a>        
      </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample11.gif" class="style5"><strong>11-Plaster_b</strong></a>        
      </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample12.gif" class="style5"><strong>12-Rough Paper</strong></a>        
      </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample13.gif" class="style5"><strong>13-Artificial Grass</strong></a>
      </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample14.gif" class="style5"><strong>14-Roof Shingle</strong></a>        </td></tr><tr>
      <td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample08.gif"><img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut08.gif"></a>
      </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample9.gif"><img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut09.gif"></a>
      </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample10.gif"><img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut10.gif"></a>
      </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample11.gif"><img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut11.gif"></a>
      </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample12.gif"><img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut12.gif"></a>
      </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample13.gif"><img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut13.gif"></a>
      </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample14.gif"><img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut14.gif"></a>
      </td></tr><tr class="style4">
        <td valign="baseline">      
        </td><td valign="baseline">      
        </td><td valign="baseline">      
        </td><td valign="baseline">      
        </td><td valign="baseline">      
        </td><td valign="baseline">      
        </td><td valign="baseline">      
      </td></tr><tr class="style4">
        <td valign="baseline">      
        </td><td valign="baseline">      
        </td><td valign="baseline">      
        </td><td valign="baseline">      
        </td><td valign="baseline">      
        </td><td valign="baseline">      
        </td><td valign="baseline">      
      </td></tr><tr class="style4">
        <td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample15.gif" class="style5"><strong>15-Aluminum Foil</strong></a>          
        </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample16.gif" class="style5"><strong>16-Cork </strong></a>          
        </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample17.gif" class="style5"><strong>17-Rough Tile</strong></a>          
        </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample18.gif" class="style5"><strong>18-Rug_a</strong></a>          
        </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample19.gif" class="style5"><strong>19-Rug_b</strong></a>          
        </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample20.gif" class="style5"><strong>20-Styrofoam</strong></a>          
        </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample21.gif" class="style5"><strong>21-Sponge</strong></a>          </td></tr><tr>
        <td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample15.gif">           <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut15.gif"> </a>
        </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample16.gif">           <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut16.gif"> </a>
        </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample17.gif">           <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut17.gif"></a>
        </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample18.gif">           <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut18.gif"> </a>
        </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample19.gif">           <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut19.gif"></a>
        </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample20.gif">           <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut20.gif"> </a>
        </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample21.gif">           <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut21.gif"> </a>
          </td></tr><tr class="style5">
            <td valign="baseline">          
            </td><td valign="baseline">          
            </td><td valign="baseline">          
            </td><td valign="baseline">          
            </td><td valign="baseline">          
            </td><td valign="baseline">          
            </td><td valign="baseline">          
          </td></tr><tr class="style5">
            <td valign="baseline">          
            </td><td valign="baseline">          
            </td><td valign="baseline">          
            </td><td valign="baseline">          
            </td><td valign="baseline">          
            </td><td valign="baseline">          
            </td><td valign="baseline">          
          </td></tr><tr class="style5">
            <td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample22.gif" class="style5"><strong>22-Lambswool</strong></a>              
            </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample23.gif" class="style5"><strong>23-Lettuce Leaf</strong></a>              
            </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample24.gif" class="style5"><strong>24-Rabbit Fur </strong></a>              
            </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample25.gif" class="style5"><strong>25-Quarry Tile</strong></a>              
            </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample26.gif" class="style5"><strong>26-Loofa</strong></a>              
            </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample27.gif" class="style5"><strong>27-Insulation </strong></a>              
            </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample28.gif" class="style5"><strong>28-Crumpled Paper </strong></a>              </td></tr><tr>
            <td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample22.gif">               <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut22.gif"> </a>
            </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample23.gif">               <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut23.gif"> </a>
            </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample24.gif">               <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut24.gif"> </a>
            </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample25.gif">               <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut25.gif"></a>            
            </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample26.gif">               <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut26.gif"></a>            
            </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample27.gif">              <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut27.gif"> </a>
            </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample28.gif">               <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut28.gif"></a>
                  </td></tr><tr class="style4">
                    <td valign="baseline">                  
                    </td><td valign="baseline">                  
                    </td><td valign="baseline">                  
                    </td><td valign="baseline">                  
                    </td><td valign="baseline">                  
                    </td><td valign="baseline">                  
                    </td><td valign="baseline">                  
                  </td></tr><tr class="style4">
                    <td valign="baseline">                  
                    </td><td valign="baseline">                  
                    </td><td valign="baseline">                  
                    </td><td valign="baseline">                  
                    </td><td valign="baseline">                  
                    </td><td valign="baseline">                  
                    </td><td valign="baseline">                  
                  </td></tr><tr class="style4">
                    <td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample29.gif" class="style5"><strong>29-(2 zoomed)</strong></a>                      
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample30.gif" class="style5"><strong>30-(11 zoomed)</strong></a>                      
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample31.gif" class="style5"><strong>31-(12 zoomed) </strong></a>                      
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample32.gif" class="style5"><strong>32-(14 zoomed) </strong></a>                      
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample33.gif" class="style5"><strong>33-Slate_a </strong></a>                      
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample34.gifl" class="style5"><strong>34-Slate_b </strong></a>                      
                  </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample35.gif" class="style5"><strong>35-Painted Spheres </strong></a>                      </td></tr><tr>
                    <td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample29.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut29.gif"></a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample30.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut30.gif"> </a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample31.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut31.gif"> </a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample32.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut32.gif"> </a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample33.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut33.gif"> </a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample34.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut34.gif"> </a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample35.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut35.gif"></a>
                                  </td></tr><tr class="style4">
                                    <td valign="baseline">                                  
                                    </td><td valign="baseline">                                  
                                    </td><td valign="baseline">                                  
                                    </td><td valign="baseline">                                  
                                    </td><td valign="baseline">                                  
                                    </td><td valign="baseline">                                  
                                    </td><td valign="baseline">                                  
                  </td></tr><tr class="style4">
                    <td valign="baseline">                                  
                    </td><td valign="baseline">                                  
                    </td><td valign="baseline">                                  
                    </td><td valign="baseline">                                  
                    </td><td valign="baseline">                                  
                    </td><td valign="baseline">                                  
                    </td><td valign="baseline">                                  
                  </td></tr><tr class="style4">
                    <td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample36.gif" class="style5"><strong>36-Limestone</strong> </a>                                      
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample37.gif" class="style5"><strong>37-Brick_a</strong></a>                                      
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample38.gif" class="style5"><strong>38-Ribbed Paper</strong></a>                                      
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample39.gif" class="style5"><strong>39-Human Skin</strong></a>                                      
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample40.gif" class="style5"><strong>40-Straw</strong></a>                                      
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample41.gif" class="style5"><strong>41-Brick_b</strong></a>                                      
                  </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample42.gif" class="style5"><strong>42-Corduroy</strong></a>                                      </td></tr><tr>
                    <td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample36.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut36.gif"></a>                    
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample37.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut37.gif"></a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample38.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut38.gif"></a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample39.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut39.gif"></a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample40.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut40.gif"></a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample41.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut41.gif"></a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample42.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut42.gif"></a>
                                                  </td></tr><tr class="style4">
                                                    <td valign="baseline">                                                  
                                                    </td><td valign="baseline">                                                  
                                                    </td><td valign="baseline">                                                  
                                                    </td><td valign="baseline">                                                  
                                                    </td><td valign="baseline">                                                  
                                                    </td><td valign="baseline">                                                  
                                                    </td><td valign="baseline">                                                  
                  </td></tr><tr class="style4">
                    <td valign="baseline">                                                  
                    </td><td valign="baseline">                                                  
                    </td><td valign="baseline">                                                  
                    </td><td valign="baseline">                                                  
                    </td><td valign="baseline">                                                  
                    </td><td valign="baseline">                                                  
                    </td><td valign="baseline">                                                  
                  </td></tr><tr class="style4">
                    <td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample43.gif" class="style5"><strong>43-Salt Crystals</strong></a>                                                      
                    </td><td valign="baseline"><a href="./about44.html" class="style5"><strong>44-Linen</strong></a>                                                      
                    </td><td valign="baseline"><a href="./about45.html" class="style5"><strong>45-Concrete_a</strong></a>                                                      
                    </td><td valign="baseline"><a href="./about46.html" class="style5"><strong>46-Cotton</strong></a>                                                      
                    </td><td valign="baseline"><a href="./about47.html" class="style5"><strong>47-Stones</strong></a>                                                      
                    </td><td valign="baseline"><a href="./about48.html" class="style5"><strong>48-Brown Bread</strong></a>                                                      
                  </td><td valign="baseline"><a href="./about49.html" class="style5"><strong>49-Concrete_b</strong></a>                                                      </td></tr><tr>
                    <td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample43.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut43.gif"></a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample44.gif"><img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut44.gif"></a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample45.gif"><img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut45.gif"></a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample46.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut46.gif"></a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample47.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut47.gif"></a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample48.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut48.gif"></a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample49.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut49.gif"></a>
                  </td></tr><tr class="style4">
                    <td valign="baseline">                  
                    </td><td valign="baseline">                  
                    </td><td valign="baseline">                  
                    </td><td valign="baseline">                  
                    </td><td valign="baseline">                  
                    </td><td valign="baseline">                  
                    </td><td valign="baseline">                  
                  </td></tr><tr class="style4">
                    <td valign="baseline">                  
                    </td><td valign="baseline">                  
                    </td><td valign="baseline">                  
                    </td><td valign="baseline">                  
                    </td><td valign="baseline">                  
                    </td><td valign="baseline">                  
                    </td><td valign="baseline">                  
                  </td></tr><tr class="style4">
                                                                                    <td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample50.gif" class="style5"><strong>50-Concrete_c</strong></a>                                                                                      
                                                                                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample51.gif" class="style5"><strong>51-Corn Husk</strong></a>                                                                                      
                                                                                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample52.gif" class="style5"><strong>52-White Bread</strong></a>                                                                                      
                                                                                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample53.gif" class="style5"><strong>53-Soleirolia Plant</strong></a>                                                                                      
                                                                                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample54.gif" class="style5"><strong>54-Wood_a</strong></a>                                                                                      
                                                                                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample55.gif" class="style5"><strong>55-Orange Peel</strong></a>                                                                                      
                  </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample56.gif" class="style5"><strong>56-Wood_b</strong></a>                                                                                      </td></tr><tr>
                    <td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample50.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut50.gif"></a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample51.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut51.gif"></a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample52.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut52.gif"></a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample53.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut53.gif"></a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample54.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut54.gif"></a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample55.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut55.gif"></a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample56.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut56.gif"></a>
                                                                                                                                                  </td></tr><tr>
                                                                                                                                                    <td valign="baseline" class="style4">                                                                                                                                                  
                                                                                                                                                    </td><td valign="baseline" class="style4">                                                                                                                                                  
                                                                                                                                                    </td><td valign="baseline" class="style4">                                                                                                                                                  
                                                                                                                                                    </td><td valign="baseline" class="style4">                                                                                                                                                  
                                                                                                                                                    </td><td valign="baseline" class="style4">                                                                                                                                                  
                  </td></tr><tr>
                    <td valign="baseline" class="style4">                                                                                                                                                  
                    </td><td valign="baseline" class="style4">                                                                                                                                                  
                    </td><td valign="baseline" class="style4">                                                                                                                                                  
                    </td><td valign="baseline" class="style4">                                                                                                                                                  
                    </td><td valign="baseline" class="style4">                                                                                                                                                  
                  </td></tr><tr>
                    <td height="26" valign="baseline" class="style4"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample57.gif" class="style5"><strong>57-Peacock Feather</strong></a>                                                                                                                                                      
                    </td><td valign="baseline" class="style4"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample58.gif" class="style5"><strong>58-Tree Bark</strong></a>                                                                                                                                                      
                    </td><td valign="baseline" class="style4"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample59.gif" class="style5"><strong>59-Cracker_a</strong></a>                                                                                                                                                      
                    </td><td valign="baseline" class="style4"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample60.gif" class="style5"><strong>60-Cracker_b</strong></a>                                                                                                                                                      
                  </td><td valign="baseline" class="style4"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample61.gif" class="style5"><strong>61-Moss</strong></a>                                                                                                                                                      </td></tr><tr>
                    <td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample57.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut57.gif"> </a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample58.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut58.gif"></a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample59.gif"><img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut59.gif"></a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample60.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut60.gif"></a>
                    </td><td valign="baseline"><a href="https://cave.cs.columbia.edu/old/software/curet/gif/sample61.gif">                      <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut61.gif"></a>
                  </td></tr></tbody></table>
              </center></td>
          </tr>
          <tr>
            <td valign="baseline"><div align="center">              <em>
              <div align="left">                </div>
            </em>
                <div align="left"></div>
                <div align="left"><hr align="left" size="4">
                  <p><router-link to="/repository/CURET"> [Return to Database Home Page] </router-link></p>
                  </div>
            </div></td>
          </tr>
        </tbody></table>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>